import logger from '@logger/logger';
import { isAfter } from 'date-fns';


export function validateDate(value: string, dateAfter?:string) {
  const [d, m, y] = value.split('/');
  value = `${m}/${d}/${y}`;
  var resp = Date.parse(value);
  // console.log(value);
  // console.log(new Date(value));
  if(dateAfter == undefined){
    dateAfter = new Date().toDateString();
  }
  return isNaN(resp) || isAfter(new Date(value), new Date(dateAfter));
}

export function validateCPF(value: string) {
  var sum;
  var rest;
  sum = 0;
  logger.debug(value);
  if (value == '00000000000') return false;
  for (let i = 1; i <= 9; i++) {
    sum = sum + parseInt(value.substring(i - 1, i)) * (11 - i);
  }

  rest = (sum * 10) % 11;
  if (rest == 10 || rest == 11) rest = 0;
  if (rest != parseInt(value.substring(9, 10))) return false;

  sum = 0;
  for (let i = 1; i <= 10; i++) {
    sum = sum + parseInt(value.substring(i - 1, i)) * (12 - i);
  }
  rest = (sum * 10) % 11;
  if (rest == 10 || rest == 11) rest = 0;
  if (rest != parseInt(value.substring(10, 11))) return false;
  return true;
}
// Regex para validação de string no formato CNPJ
export const regexCNPJ = /^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/;

// Método de validação
// Referência: https://pt.wikipedia.org/wiki/Cadastro_Nacional_da_Pessoa_Jur%C3%ADdica
// E https://gist.github.com/alexbruno/6623b5afa847f891de9cb6f704d86d02
export function validateCNPJ(value: string | number | number[] = '') {
  if (!value) return false;

  // Aceita receber o valor como string, número ou array com todos os dígitos
  const isString = typeof value === 'string';
  const validTypes =
    isString || Number.isInteger(value) || Array.isArray(value);

  // Elimina valor de tipo inválido
  if (!validTypes) return false;

  // Filtro inicial para entradas do tipo string
  if (isString) {
    // Teste Regex para veificar se é uma string apenas dígitos válida
    const digitsOnly = /^\d{14}$/.test(value);
    // Teste Regex para verificar se é uma string formatada válida
    const validFormat = regexCNPJ.test(value);
    // Verifica se o valor passou em ao menos 1 dos testes
    const isValid = digitsOnly || validFormat;

    // Se o formato não é válido, retorna inválido
    if (!isValid) return false;
  }

  // Elimina tudo que não é dígito
  const numbers = matchNumbers(value);

  // Valida a quantidade de dígitos
  if (numbers.length !== 14) return false;

  // Elimina inválidos com todos os dígitos iguais
  const items = [...new Set(numbers)];
  if (items.length === 1) return false;

  // Separa os 2 últimos dígitos verificadores
  const digits = numbers.slice(12);

  // Valida 1o. dígito verificador
  const digit0 = validCalc(12, numbers);
  if (digit0 !== digits[0]) return false;

  // Valida 2o. dígito verificador
  const digit1 = validCalc(13, numbers);
  return digit1 === digits[1];
}

// Cálculo validador
function validCalc(x: number, numbers: number[]) {
  const slice = numbers.slice(0, x);
  let factor = x - 7;
  let sum = 0;

  for (let i = x; i >= 1; i--) {
    const n = slice[x - i];
    sum += n * factor--;
    if (factor < 2) factor = 9;
  }

  const result = 11 - (sum % 11);

  return result > 9 ? 0 : result;
}

// Elimina tudo que não é dígito
function matchNumbers(value: string | number | number[] = '') {
  const match = value.toString().match(/\d/g);
  return Array.isArray(match) ? match.map(Number) : [];
}


const precision = 2;

export const formatReal = (valueAsNumber: number) => {
  let valueAsString = valueAsNumber.toString();
  const [integerPart, decimalPart = ''] = valueAsString.split('.');

  if (decimalPart) {
    valueAsString = `${integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      '.'
    )},${decimalPart.padEnd(precision, '0')}`;
  } else {
    valueAsString = `${integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      '.'
    )},${'0'.repeat(precision)}`;
  }
  return valueAsString;
};

export const parseReal = (valueAsString: string) => {
  let valueAsNumber = 0;

  try {
    let parsedValueAsString = valueAsString.replace(/\D/g, '');

    parsedValueAsString = parsedValueAsString.padStart(precision + 1, '0');

    parsedValueAsString = `${parsedValueAsString.substring(
      0,
      parsedValueAsString.length - precision
    )}.${parsedValueAsString.slice(precision * -1)}`;
    valueAsNumber = parseFloat(parsedValueAsString);
  } catch {
    valueAsNumber = 0;
  }
  return valueAsNumber;
};
