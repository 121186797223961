export const messageTextAreaName = 'message';

export const codProduto = 'cod_produto';

export const cnpjOptions = [
  { label: '23.694.816/0005-91', value: '23694816000591' },
  { label: '42.463.762/0001-74', value: '42463762000174' },
  { label: '01.937.526/0051-24', value: '01937526005124' },
  { label: '01.937.526/0039-38', value: '01937526003938' },
  { label: '01.937.526/0018-03', value: '01937526001803' },
  { label: '01.937.526/0034-23', value: '01937526003423' },
  { label: '01.937.526/0057-10', value: '01937526005710' },
  { label: '01.937.526/0052-05', value: '01937526005205' },
  { label: '01.937.526/0067-91', value: '01937526006791' },
  { label: '01.937.526/0066-00', value: '01937526006600' },
  { label: '01.937.526/0068-72', value: '01937526006872' },
  { label: '01.937.526/0070-97', value: '01937526007097' },
  { label: '23.547.554/0004-51', value: '23547554000451' },
  { label: '01.937.526/0071-78', value: '01937526007178' },
  { label: '01.937.526/0074-10', value: '01937526007410' },
  { label: '01.937.526/0075-00', value: '01937526007500' },
  { label: '01.937.526/0077-63', value: '01937526007763' },
  { label: '01.937.526/0079-25', value: '01937526007925' },
  { label: '01.937.526/0083-01', value: '01937526008301' },
  { label: '01.937.526/0080-69', value: '01937526008069' },
  { label: '01.937.526/0086-54', value: '01937526008654' },
  { label: '01.937.526/0085-73', value: '01937526008573' },
  { label: '01.937.526/0092-00', value: '01937526009200' },
  { label: '01.937.526/0094-64', value: '01937526009464' },
  { label: '23.547.554/0009-66', value: '23547554000966' },
  { label: '01.937.526/0098-98', value: '01937526009898' },
  { label: '01.937.526/0097-07', value: '01937526009707' },
  { label: '01.937.526/0099-79', value: '01937526009979' },
  { label: '01.937.526/0102-09', value: '01937526010209' },
  { label: '00.772.527/0010-25', value: '00772527001025' },
  { label: '00.772.527/0011-06', value: '00772527001106' },
  { label: '01.937.526/0014-80', value: '01937526001480' },
  { label: '01.937.526/0049-00', value: '01937526004900' },
  { label: '46.670.520/0001-02', value: '46670520000102' },
  { label: '09.060.964/0280-29', value: '09060964028029' },
  { label: '44.017.933/0002-48', value: '44017933000248' },
  { label: '47.134.058/0001-91', value: '47134058000191' },
  { label: '48.426.276/0001-62', value: '48426276000162' },
  { label: '22.685.526/0003-57', value: '22685526000357' },
  { label: '23.547.554/0008-85', value: '23547554000885' },
  { label: '00.772.527/0004-87', value: '00772527000487' },
  { label: '00.772.527/0002-15', value: '00772527000215' },
  { label: '26.716.832/0009-50', value: '26716832000950' },
  { label: '05.906.104/0001-83', value: '05906104000183' },
  { label: '09.060.964/0170-94', value: '09060964017094' },
  { label: '09.060.964/0171-75', value: '09060964017175' },
  { label: '09.060.964/0168-70', value: '09060964016870' },
  { label: '09.060.964/0169-50', value: '09060964016950' },
  { label: '18.162.010/0001-99', value: '18162010000199' },
  { label: '09.119.809/0001-01', value: '09119809000101' },
  { label: '09.060.964/0173-37', value: '09060964017337' },
  { label: '20.890.360/0001-13', value: '20890360000113' },
  { label: '02.089.436/0001-24', value: '02089436000124' },
  { label: '00.772.527/0001-34', value: '00772527000134' },
  { label: '27.600.277/0001-01', value: '27600277000101' },
  { label: '27.600.277/0003-73', value: '27600277000373' },
  { label: '06.061.283/0002-49', value: '06061283000249' },
  { label: '36.999.841/0001-00', value: '36999841000100' },
  { label: '38.322.485/0001-02', value: '38322485000102' },
  { label: '17.729.812/0001-75', value: '17729812000175' },
  { label: '24.068.621/0001-75', value: '24068621000175' },
  { label: '02.876.218/0005-63', value: '02876218000563' },
  { label: '02.876.218/0001-30', value: '02876218000130' },
  { label: '02.876.218/0006-44', value: '02876218000644' },
  { label: '09.060.964/0174-18', value: '09060964017418' },
  { label: '23.694.816/0004-00', value: '23694816000400' },
  { label: '26.317.428/0001-56', value: '26317428000156' },
  { label: '23.694.816/0002-49', value: '23694816000249' },
  { label: '23.694.816/0003-20', value: '23694816000320' },
  { label: '26.317.428/0002-37', value: '26317428000237' },
  { label: '23.694.816/0006-72', value: '23694816000672' },
  { label: '37.175.490/0001-77', value: '37175490000177' },
  { label: '19.155.920/0001-07', value: '19155920000107' },
  { label: '38.230.556/0001-47', value: '38230556000147' },
  { label: '06.061.283/0001-68', value: '06061283000168' },
  { label: '00.772.527/0008-00', value: '00772527000800' },
  { label: '21.641.523/0001-97', value: '21641523000197' },
  { label: '10.841.547/0005-05', value: '10841547000505' },
  { label: '23.277.673/0001-99', value: '23277673000199' },
  { label: '09.060.964/0167-99', value: '09060964016799' },
  { label: '22.937.922/0001-62', value: '22937922000162' },
  { label: '01.937.526/0042-33', value: '01937526004233' },
  { label: '23.192.557/0001-77', value: '23192557000177' },
  { label: '10.841.547/0015-79', value: '10841547001579' },
  { label: '10.841.547/0012-26', value: '10841547001226' },
  { label: '22.685.526/0002-76', value: '22685526000276' },
  { label: '23.247.934/0019-57', value: '23247934001957' },
  { label: '23.247.934/0001-28', value: '23247934000128' },
  { label: '23.247.934/0020-90', value: '23247934002090' },
  { label: '19.164.899/0004-59', value: '19164899000459' },
  { label: '23.247.934/0021-71', value: '23247934002171' },
  { label: '23.241.380/0001-51', value: '23241380000151' },
  { label: '10.841.547/0022-06', value: '10841547002206' },
  { label: '01.937.526/0012-18', value: '01937526001218' },
  { label: '23.547.554/0001-09', value: '23547554000109' },
  { label: '44.017.933/0001-67', value: '44017933000167' },
  { label: '23.547.554/0006-13', value: '23547554000613' },
  { label: '23.247.934/0005-51', value: '23247934000551' },
  { label: '25.070.680/0001-40', value: '25070680000140' },
  { label: '23.247.934/0004-70', value: '23247934000470' },
  { label: '23.247.934/0018-76', value: '23247934001876' },
  { label: '23.247.934/0023-33', value: '23247934002333' },
  { label: '10.841.547/0023-89', value: '10841547002389' },
  { label: '23.547.554/0003-70', value: '23547554000370' },
  { label: '20.255.764/0006-40', value: '20255764000640' },
  { label: '23.247.934/0007-13', value: '23247934000713' },
  { label: '23.247.934/0008-02', value: '23247934000802' },
  { label: '23.247.934/0006-32', value: '23247934000632' },
  { label: '10.841.547/0024-60', value: '10841547002460' },
  { label: '23.247.934/0010-19', value: '23247934001019' },
  { label: '23.247.934/0011-08', value: '23247934001108' },
  { label: '26.718.185/0001-68', value: '26718185000168' },
  { label: '21.524.937/0001-36', value: '21524937000136' },
  { label: '23.547.554/0005-32', value: '23547554000532' },
  { label: '27.717.284/0001-98', value: '27717284000198' },
  { label: '26.716.832/0002-83', value: '26716832000283' },
  { label: '44.017.933/0001-48', value: '44017933000148' },
  { label: '09.060.964/0074-55', value: '09060964007455' },
  { label: '19.246.697/0002-94', value: '19246697000294' },
  { label: '23.247.934/0002-09', value: '23247934000209' },
  { label: '23.547.554/0002-90', value: '23547554000290' },
  { label: '01.937.526/0081-40', value: '01937526008140' },
  { label: '23.247.934/0014-42', value: '23247934001442' },
  { label: '23.247.934/0016-04', value: '23247934001604' },
  { label: '00.772.527/0009-91', value: '00772527000991' },
  { label: '19.164.899/0007-00', value: '19164899000700' },
  { label: '29.284.524/0001-33', value: '29284524000133' },
  { label: '15.375.919/0001-37', value: '15375919000137' },
  { label: '26.716.832/0003-64', value: '26716832000364' },
  { label: '30.898.825/0001-36', value: '30898825000136' },
  { label: '09.060.964/0164-46', value: '09060964016446' },
  { label: '09.060.964/0215-20', value: '09060964021520' },
  { label: '06.235.514/0023-18', value: '06235514002318' },
  { label: '09.060.964/0186-51', value: '09060964018651' },
  { label: '10.841.547/0029-74', value: '10841547002974' },
  { label: '09.060.964/0165-27', value: '09060964016527' },
  { label: '32.907.402/0001-60', value: '32907402000160' },
  { label: '09.060.964/0188-13', value: '09060964018813' },
  { label: '33.535.594/0001-94', value: '33535594000194' },
  { label: '01.937.526/0090-30', value: '01937526009030' },
  { label: '09.060.964/0189-02', value: '09060964018902' },
  { label: '14.437.943/0002-71', value: '14437943000271' },
  { label: '33.324.115/0001-90', value: '33324115000190' },
  { label: '26.716.832/0007-98', value: '26716832000798' },
  { label: '09.060.964/0190-38', value: '09060964019038' },
  { label: '09.060.964/0233-02', value: '09060964023302' },
  { label: '34.169.394/0002-09', value: '34169394000209' },
  { label: '00.772.527/0005-68', value: '00772527000568' },
  { label: '19.164.899/0008-82', value: '19164899000882' },
  { label: '09.060.964/0214-40', value: '09060964021440' },
  { label: '09.060.964/0192-08', value: '09060964019208' },
  { label: '26.716.832/0006-07', value: '26716832000607' },
  { label: '41.828.407/0001-99', value: '41828407000199' },
  { label: '09.060.964/0193-80', value: '09060964019380' },
  { label: '09.060.964/0187-32', value: '09060964018732' },
  { label: '09.060.964/0227-64', value: '09060964022764' },
  { label: '20.255.764/0009-92', value: '20255764000992' },
  { label: '26.716.832/0005-26', value: '26716832000526' },
  { label: '09.060.964/0216-01', value: '09060964021601' },
  { label: '34.865.872/0001-34', value: '34865872000134' },
  { label: '73.520.801/0001-51', value: '73520801000151' },
  { label: '09.060.964/0161-01', value: '09060964016101' },
  { label: '35.575.578/0001-50', value: '35575578000150' },
  { label: '34.481.154/0001-64', value: '34481154000164' },
  { label: '09.060.964/0232-21', value: '09060964023221' },
  { label: '09.060.964/0191-19', value: '09060964019119' },
  { label: '06.235.514/0008-89', value: '06235514000889' },
  { label: '01.937.526/0100-47', value: '01937526010047' },
  { label: '09.060.964/0194-61', value: '09060964019461' },
  { label: '06.235.514/0029-03', value: '06235514002903' },
  { label: '35.726.647/0001-80', value: '35726647000180' },
  { label: '01.937.526/0088-16', value: '01937526008816' },
  { label: '18.555.248/0001-84', value: '18555248000184' },
  { label: '19.246.697/0001-03', value: '19246697000103' },
  { label: '17.314.329/0054-31', value: '17314329005431' },
  { label: '40.498.268/0001-10', value: '40498268000110' },
  { label: '38.731.844/0001-85', value: '38731844000185' },
  { label: '42.292.646/0001-30', value: '42292646000130' },
  { label: '39.762.763/0001-05', value: '39762763000105' },
  { label: '09.060.964/0127-00', value: '09060964012700' },
  { label: '39.721.114/0003-28', value: '39721114000328' },
  { label: '32.768.338/0001-84', value: '32768338000184' },
  { label: '39.721.114/0002-47', value: '39721114000247' },
  { label: '09.060.964/0135-01', value: '09060964013501' },
  { label: '09.060.964/0138-54', value: '09060964013854' },
  { label: '09.060.964/0131-88', value: '09060964013188' },
  { label: '09.060.964/0257-80', value: '09060964025780' },
  { label: '09.060.964/0153-93', value: '09060964015393' },
  { label: '09.060.964/0065-64', value: '09060964006564' },
  { label: '09.060.964/0151-21', value: '09060964015121' },
  { label: '09.060.964/0142-30', value: '09060964014230' },
  { label: '09.060.964/0158-06', value: '09060964015806' },
  { label: '09.060.964/0139-35', value: '09060964013935' },
  { label: '09.060.964/0003-61', value: '09060964000361' },
  { label: '09.060.964/0273-08', value: '09060964027308' },
  { label: '09.060.964/0069-98', value: '09060964006998' },
  { label: '09.060.964/0264-09', value: '09060964026409' },
  { label: '09.060.964/0088-50', value: '09060964008850' },
  { label: '09.060.964/0271-38', value: '09060964027138' },
  { label: '09.060.964/0261-66', value: '09060964026166' },
  { label: '09.060.964/0262-47', value: '09060964026247' },
  { label: '09.060.964/0136-92', value: '09060964013692' },
  { label: '09.060.964/0064-83', value: '09060964006483' },
  { label: '09.060.964/0260-85', value: '09060964026085' },
  { label: '09.060.964/0076-17', value: '09060964007617' },
  { label: '33.745.245/0001-05', value: '33745245000105' },
  { label: '09.060.964/0071-02', value: '09060964007102' },
  { label: '09.060.964/0259-41', value: '09060964025941' },
  { label: '34.481.154/0002-45', value: '34481154000245' },
  { label: '39.721.114/0004-09', value: '39721114000409' },
  { label: '09.060.964/0128-82', value: '09060964012882' },
  { label: '09.060.964/0263-28', value: '09060964026328' },
  { label: '09.060.964/0132-69', value: '09060964013269' },
  { label: '09.060.964/0274-80', value: '09060964027480' },
  { label: '09.060.964/0166-08', value: '09060964016608' },
  { label: '09.060.964/0059-16', value: '09060964005916' },
  { label: '09.060.964/0078-89', value: '09060964007889' },
  { label: '37.805.492/0001-00', value: '37805492000100' },
  { label: '09.060.964/0266-70', value: '09060964026670' },
  { label: '10.841.547/0035-12', value: '10841547003512' },
  { label: '49.618.094/0001-56', value: '49618094000156' },
  { label: '01.937.526/0093-83', value: '01937526009383' },
  { label: '01.937.526/0108-02', value: '01937526010802' },
  { label: '01.937.526/0109-85', value: '01937526010985' },
  { label: '01.937.526/0110-19', value: '01937526011019' },
  { label: '09.060.964/0305-11', value: '09060964030511' },
  { label: '09.060.964/0285-33', value: '09060964028533' },
  { label: '09.060.964/0278-04', value: '09060964027804' },
  { label: '49.618.094/0002-37', value: '49618094000237' },
  { label: '23.694.816/0001-68', value: '23694816000168' },
];
